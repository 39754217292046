import { useEffect, useState } from "react";
import Floor from "./Floor";
import Logo from "./images/logo.png"


function App() {
  const [floorNumber,setFloorNumber] = useState(5)
  const [floors,setFloors] = useState([])
  const submitHandle=(event)=>{
    event.preventDefault()
    setFloorNumber(+event.target[0].value)
  }
  const keyupHandle=(event)=>{
    if (event.key === "Enter") {
      event.preventDefault();
      var current = document.activeElement;
      var fields = Array.from(document.querySelectorAll("[tabindex]")).sort(function(a, b) {
        return a.tabIndex - b.tabIndex;
      });
      var index = fields.indexOf(current);
      if (index > -1 && (index + 1) < fields.length) {
        var next = fields[index + 1];
        while (next.disabled) {
          index++;
          if (index + 1 >= fields.length) {
            break;
          }
          next = fields[index + 1];
        }
        next.focus();
      }
    }
  }
  useEffect(()=>{
    document.addEventListener("keyup", function(event) {
      
    });
  },[])
    useEffect(()=>{
      setFloors([])
      for(let i=0;i<floorNumber;i++){
        setFloors((currentValue)=>[...currentValue,<Floor floors={floorNumber} index={i} fnc={keyupHandle} />])
      }
    },[floorNumber])
  return (
    <div className="App">
      <div className="Logo">
        <img src={Logo} alt="" />
      </div>
      <div className="controller">
        <form onSubmit={submitHandle}>
          <section>
          <span>Durak Sayısı</span>
          <input type="number" min={2} defaultValue={floorNumber}  />
          </section>
          <button type="submit">Oluştur</button>
        </form>
      </div>
      <div className="floors">
        <div className="title">
          <h2>Durak</h2>
          <h2>Aşağı</h2>
          <h2>Yukarı</h2>
        </div>
        {
          floors.map((value,i)=><div className="floor" key={i}>{value}</div>)
        }
      </div>
    </div>
  );
}

export default App;
