export default function Floor({
      floors,
      index,
      fnc,
}){
      return(
            <>
                  <section>{floors-index}</section>
                  <input disabled={index===0} onKeyUp={(event)=>fnc(event)} tabIndex={index+1} type="tel" maxLength={5} />
                  <input disabled={index+1===floors} onKeyUp={(event)=>fnc(event)} tabIndex={floors*2-index} type="tel" maxLength={5} />
            </>
      )
}